<template>
  <div>
    <portal to="page-top-title">Edit Badword #{{ id }}</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'badwords-single', params: { id: id } }" icon="fa-eye" text>Back to Badword</btn>
    </portal>

    <box-form action="edit" data-prop="formData" redirect="badwords-single" :url="`/badwords/${id}`" :loaded="loaded" loader>
      <template slot="content">
        <row>
          <column-input>
            <form-group-input name="name" :model="formData.value" :min="2" required/>
          </column-input>
          <column-input>
            <form-group-select name="category_id" title="Category" :model="formData.category_id"
                               :options="categories" :disabled="!categories.length" required/>
          </column-input>
          <column-input :md="2">
            <form-group-check name="active" :model="formData.active"/>
          </column-input>
        </row>
      </template>
    </box-form>
  </div>
</template>

<script>
import BoxForm from '@/views/components/data/BoxForm'
import Btn from '@/views/components/simple/Btn'
import FormGroupCheck from '@/views/components/form/FormGroupCheck'
import FormGroupInput from '@/views/components/form/FormGroupInput'
import FormGroupSelect from '@/views/components/form/FormGroupSelect'
import Form from '@/modules/utils/Form'

export default {
  name: 'BadwordsEdit',
  metaInfo () {
    return { title: `Badword #${this.id} | Edit` }
  },
  components: {
    BoxForm,
    Btn,
    FormGroupCheck,
    FormGroupInput,
    FormGroupSelect
  },
  computed: {
    id () { return this.$route.params.id }
  },
  data () {
    return {
      loaded: false,
      categories: [],
      formData: {
        value: Form.types.input(),
        active: Form.types.boolean(),
        category_id: Form.types.select()
      }
    }
  },
  created () {
    this.loadEntity()
    this.loadCategories()
  },
  methods: {
    loadEntity () {
      this.$http
        .get(`/badwords/${this.id}`)
        .then((res) => {
          const { data: { data } } = res

          this.formData.value.value = data.value
          this.formData.active.value = data.active
          this.formData.category_id.value = { id: data.category.id, name: data.category.name }

          this.loaded = true
        })
    },
    loadCategories () {
      this.$http
        .get('/badwords/categories', { params: { no_pages: 1 } })
        .then((res) => {
          const { data: { data } } = res

          this.categories = data.map((category) => {
            return { id: category.id, name: category.name }
          })
        })
    }
  }
}
</script>
